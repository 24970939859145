import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"

import React, { FC } from "react"
import Image from "react-bootstrap/Image"
import { Layout } from "@layouts/default"

import { TechnologiesSection } from "@components/homepage/technologies-section/technologies-section"
import { Slider } from "@components/slider/slider"
import { Hero } from "@components/hero/hero"
import { LinkButton } from "@components/link-button/link-button"

import {
  heroButtonsWrapper,
  sectionTitleStyles,
  sectionUserExperience,
  sectionIntegrations,
} from "@styles/pages/index/index-styles"
import { SEO } from "@components/seo"
import IntegrationsImage from "@images/homepage/integrations.svg"

const title = "Authsider \n Identity Simplified"
const description =
  "We provide developers with a streamlined approach to Identity. Authentication & authorisation for web, native and hybrid applications has never been this simple."

interface IndexProps {
  data: {
    section1Copy: {
      title: string
      description: string
      leftColumn: {
        title: string
        text: string
      }
      rightColumn: {
        title: string
        text: string
      }
    }
    section2Copy: {
      title: string
      description: string
      linkText: string
      linkUrl: string
    }
    sliderImages: {
      nodes: Array<ImageObject>
    }
    section2Image: ImageObject
  }
}

const IndexPage: FC<IndexProps> = ({ data: { sliderImages } }) => {
  return (
    <Layout>
      <SEO title="Authsider" description="" noTemplate />
      <Hero title={title} description={description}>
        <div className="buttons-wrapper" css={heroButtonsWrapper}>
          <LinkButton
            label="Check out our demos"
            type="border"
            url="https://github.com/authsider?q=sample"
            newTab
            uppercase={true}
          />
          <LinkButton
            label="Try Authsider for Free"
            type="fill"
            url="https://dashboard.authsider.io"
            uppercase={true}
          />
        </div>
      </Hero>
      {/*<CompaniesBanner />*/}
      <section
        className="homepage-section hs-user-experience"
        css={sectionUserExperience}
      >
        <Container>
          <Row>
            <Col md={6} className="hs-user-experience__left">
              <Row className="align-self-center">
                <Col md={12} className="mb-lg-5 mb-4">
                  <h2 css={sectionTitleStyles}>Customizable User Experience</h2>
                  <p>
                    Fully customise the authentication experience based your
                    organisations&apos;s branding. From custom domains to
                    service emails, you can personalise it all taking into
                    consideration the application context.
                  </p>
                </Col>
                <Col md={6} className="hs-user-experience__split-column">
                  <h3 className="hs-user-experience__subtitle">Hosted Login</h3>
                  <p className="hs-user-experience__text">
                    Leverage a pre-built, customisable login experience
                    including registration, account validation, MFA enrollment
                    and more.
                  </p>
                </Col>
                <Col md={6} className="hs-user-experience__split-column">
                  <h3 className="hs-user-experience__subtitle">
                    Embedded Login
                  </h3>
                  <p className="hs-user-experience__text">
                    Take your tailoring needs to the next level. With embedding
                    login you can build your own authentication page.
                  </p>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Slider
                images={sliderImages.nodes}
                options={{
                  showNavigation: true,
                  showPagination: true,
                  autoplay: 3000,
                }}
                sliderId={"image-slider"}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="homepage-section hs-integrations"
        css={sectionIntegrations}
      >
        <Container>
          <Row>
            <Col md={6} className="hs-integrations__left">
              <Image src={IntegrationsImage} fluid />
            </Col>

            <Col md={6}>
              <div className="hs-integrations__right-inner">
                <h2 css={sectionTitleStyles}>Out of the box integrations</h2>
                <p>
                  Google, Facebook, Apple and many more OIDC providers supported
                  to ease your users login experience.
                  <br />
                  By using account linking your users won&apos;t have to recall
                  which service they used to login anymore.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <TechnologiesSection />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    section1Copy: homepageJson(sectionId: { eq: "section1" }) {
      title
      description
      leftColumn {
        title
        text
      }
      rightColumn {
        title
        text
      }
    }
    section2Copy: homepageJson(sectionId: { eq: "section2" }) {
      title
      description
      linkText
      linkUrl
    }
    sliderImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "homepage/homepage-slider" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
        id
      }
    }
    section2Image: file(
      relativeDirectory: { eq: "homepage/section2-leftColumn" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      id
    }
  }
`

export default IndexPage
